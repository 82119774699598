import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SolutionsList from "../components/solutions-list"
import Container from "react-bootstrap/Container"
import Hero from "../components/hero"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const PillarTemplate = ({ data, pageContext, location }) => {
  const { pillar } = data;

  return (
    <Layout className='template-pillar' bodyClass='page-template' container={false}>
      <SEO
        title={pillar.name}
        description={pillar.description}
      />

      <article>
        <header>
          <Hero
            image={pillar.banner}
            className={`${pillar.slug}-hero`}
            title={'BHK ' + pillar.name}
          >
            {renderRichText(pillar.body)}
          </Hero>

        </header>

        <Container fluid='xl' as='section'>
          <h2 className='mb-4'>{pillar.solutionsTitle} Solutions</h2>
          <SolutionsList solutions={pillar.solution}/>
        </Container>

      </article>
    </Layout>
  );
}

export default PillarTemplate;

export const pageQuery = graphql`
  query PillarBySlug($slug: String!) {
    pillar: contentfulPillar(slug: {eq: $slug}) {
      name
      slug
      solutionsTitle
      solution {
        name
        slug
        description {
          description
        }
      }
      description
      body {
        raw
      }
      badge {
        svg {
          absolutePath
          content
          dataURI
          relativePath
        }
      }
      banner {
        fluid(quality: 70, maxWidth: 2400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
